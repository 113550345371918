import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 安装 VueRouter 插件
Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout/index';
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login'),
		hidden: true,
		meta: {
			title: '登录',
		},
	},
	{
		path: '/404',
		component: () => import('@/views/error/404'),
		hidden: true,
		meta: {
			title: '404',
		},
	},
	{
		path: '/',
		component: Layout,
		redirect: '/accountManager',
	},
	// {
	// 	path: '/home',
	// 	component: Layout,
	// 	redirect: '/home/dashboard/overview',
	// 	name: 'home',
	// 	meta: {
	// 		title: '数据驾驶舱',
	// 		roles: ['admin', 'common'],
	// 	},
	// 	children: [
	// 		{
	// 			path: 'dashboard',
	// 			name: 'index',
	// 			component: () => import('@/views/homePage/index'),
	// 			meta: {
	// 				title: '巡诊驻派数据驾驶舱',
	// 				roles: ['admin', 'common'],
	// 			},
	// 			children: [
	// 				{
	// 					path: 'overview',
	// 					component: () => import('@/views/homePage/overview/index'),
	// 					meta: {
	// 						title: '总览',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/medic',
	// 					component: () => import('@/views/homePage/medic/index'),
	// 					meta: {
	// 						title: '医疗服务',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/public',
	// 					component: () => import('@/views/homePage/public/index'),
	// 					meta: {
	// 						title: '公共卫生',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/tcm',
	// 					component: () => import('@/views/homePage/tcm/index'),
	// 					meta: {
	// 						title: '中医药建设',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/train',
	// 					component: () => import('@/views/homePage/train/index'),
	// 					meta: {
	// 						title: '培训服务',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/kpi',
	// 					component: () => import('@/views/homePage/kpi/index'),
	// 					meta: {
	// 						title: '绩效体系',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/visitAndDispatch',
	// 					component: () => import('@/views/homePage/visitAndDispatch/index'),
	// 					meta: {
	// 						title: '巡诊驻派',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 				{
	// 					path: '/home/dashboard/teamwork',
	// 					component: () => import('@/views/homePage/teamwork/index'),
	// 					meta: {
	// 						title: '合作企业',
	// 						roles: ['admin', 'common'],
	// 					},
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	{
		path: '/accountManager',
		component: Layout,
		redirect: '/accountManager/countyAccount',
		name: 'accountManager',
		meta: {
			title: '账户管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/accountManager/countyAccount',
				name: 'countyAccount',
				component: () => import('@/views/accountManager/countyAccount'),
				meta: {
					title: '账户管理',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/performanceManager',
		component: Layout,
		redirect: '/performanceManager/index',
		name: 'performanceManager',
		meta: {
			title: '绩效体系',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/performanceManager/index',
				name: 'index',
				component: () => import('@/views/performanceManager/index'),
				meta: {
					title: '乡镇卫生院绩效评价',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/staticTotalView',
		component: Layout,
		redirect: '/staticTotalView/userInfo',
		name: 'staticTotalView',
		meta: {
			title: '统计管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/staticTotalView/userInfo',
				name: 'userInfo',
				component: () => import('@/views/staticTotalView/userInfo'),
				meta: {
					title: '用户信息统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/familyInfo',
				name: 'familyInfo',
				component: () => import('@/views/staticTotalView/familyInfo/index'),
				meta: {
					title: '家庭信息统计',
					roles: ['admin', 'common'],
				},
			},

			{
				path: '/staticTotalView/institutionalStatistics',
				name: 'institutionalStatistics',
				component: () => import('@/views/staticTotalView/institutionalStatistics/index'),
				meta: {
					title: '机构统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/medicalStaffStatistics',
				name: 'medicalStaffStatistics',
				component: () => import('@/views/staticTotalView/medicalStaffStatistics'),
				meta: {
					title: '医护人员统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/resourceStatic',
				name: 'resourceStatic',
				component: () => import('@/views/staticTotalView/resourceStatic'),
				meta: {
					title: '资源统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/healthEqu',
				name: 'healthEqu',
				component: () => import('@/views/staticTotalView/healthEqu'),
				meta: {
					title: '康养设备统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/patrolStatistics',
				name: 'patrolStatistics',
				component: () => import('@/views/staticTotalView/patrolStatistics'),
				meta: {
					title: '巡诊计划统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/residentStatistics',
				name: 'residentStatistics',
				component: () => import('@/views/staticTotalView/residentStatistics'),
				meta: {
					title: '驻派计划统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/childImmunity',
				name: 'childImmunity',
				component: () => import('@/views/staticTotalView/childImmunity'),
				meta: {
					title: '接种预防统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/publicEmergencies',
				name: 'publicEmergencies',
				component: () => import('@/views/staticTotalView/publicEmergencies'),
				meta: {
					title: '公共突发事件统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/publicEmergencies/details',
				name: 'publicEmergenciesDetails',
				component: () => import('@/views/staticTotalView/publicEmergencies/details'),
				meta: {
					title: '公共突发事件详情',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/familyDoctor',
				name: 'familyDoctor',
				component: () => import('@/views/staticTotalView/familyDoctor/index'),
				meta: {
					title: '家庭签约医生统计',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticTotalView/familyInfo/showDetails',
				name: 'showDetails',
				component: () => import('@/views/staticTotalView/familyInfo/showDetails'),
				meta: {
					title: '家庭信息详情',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/staticManager',
		component: Layout,
		redirect: '/staticManager/medicalStaffData',
		name: 'staticManager',
		meta: {
			title: '数据分析',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/staticManager/medicalStaffData',
				name: 'medicalStaffData',
				component: () => import('@/views/staticManager/medicalStaffData/index'),
				meta: {
					title: '医护人员数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/importPersonData',
				name: 'importPersonData',
				component: () => import('@/views/staticManager/importPersonData/index'),
				meta: {
					title: '重点人群数据分析',
					roles: ['admin', 'common'],
				},
			},

			{
				path: '/staticManager/infectiousDiseaseData',
				name: 'infectiousDiseaseData',
				component: () => import('@/views/staticManager/infectiousDiseaseData/index'),
				meta: {
					title: '传染病数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/healthEduData',
				name: 'healthEduData',
				component: () => import('@/views/staticManager/healthEduData/index'),
				meta: {
					title: '健康教育数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/receiveData',
				name: 'receiveData',
				component: () => import('@/views/staticManager/receiveData/index'),
				meta: {
					title: '接转诊数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/inspectData',
				name: 'inspectData',
				component: () => import('@/views/staticManager/inspectData/index'),
				meta: {
					title: '检查检验数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/makeAroundData',
				name: 'makeAroundData',
				component: () => import('@/views/staticManager/makeAroundData/index'),
				meta: {
					title: '巡诊数据分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/residencyData',
				name: 'residencyData',
				component: () => import('@/views/staticManager/residencyData/index'),
				meta: {
					title: '驻派分析',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/staticManager/deviceData',
				name: 'deviceData',
				component: () => import('@/views/staticManager/deviceData/index'),
				meta: {
					title: '设备数据分析',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/account',
		component: Layout,
		redirect: '/account/personalInfo',
		name: 'account',
		meta: {
			title: '账号设置',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/account/personalInfo',
				name: 'personalInfo',
				component: () => import('@/views/account/personalInfo'),
				meta: {
					title: '个人信息',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/systemManager',
		component: Layout,
		redirect: '/systemManager/departmentList',
		name: 'systemManager',
		meta: {
			title: '系统设置',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/systemManager/departmentList',
				name: 'departmentList',
				component: () => import('@/views/systemManager/departmentList'),
				meta: {
					title: '科室字典',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/systemManager/postList',
				name: 'postList',
				component: () => import('@/views/systemManager/postList'),
				meta: {
					title: '岗位字典',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/systemManager/titleList',
				name: 'titleList',
				component: () => import('@/views/systemManager/titleList'),
				meta: {
					title: '职称字典',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/systemManager/dictionaryList',
				name: 'dictionaryList',
				component: () => import('@/views/systemManager/dictionaryList'),
				meta: {
					title: '字典管理',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	// 404 page must be placed at the end !!!
	{
		path: '*',
		redirect: '/404',
		hidden: true,
	},
];
const createRouter = () =>
	new VueRouter({
		// mode: 'history', // require service support
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

export default router;
