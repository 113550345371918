export default {
	login: '/governance-api/v1/Sessions', //登录
	getChinaRegions: '/common-api/v1/ChinaRegions.json', //获取省市区数据
	getRegionManagementGroups: '/governance-api/v1/RegionManagementGroups', //获取区域管理组
	modifyPasswords: '/governance-api/v1/Passwords', //修改密码
	getMedicalWorkers: '/governance-api/v1/MedicalWorkers', //获取医护人员
	getPeople: '/governance-api/v1/People', //获取人员
	getDictionaryItems: '/common-api/v1/DictionaryItems', //获取字典项
	getMedicalOrganizations: '/governance-api/v1/MedicalOrganizations', //获取医疗机构
	getVehicles: '/governance-api/v1/Vehicles', //获取车辆
	getDevices: '/governance-api/v1/Devices', //获取车辆
	getTourPlans: '/governance-api/v1/TourPlans', //获取巡查计划
	getRegionPriorityDiseases: '/governance-api/v1/RegionPriorityDiseases', //获取重点疾病
	getRegionTourIndexes: '/governance-api/v1/RegionTourIndexes', //获取巡诊覆盖率
	getBaseData: '/governance-api/v1/BaseData', //获取基础数据
	getTalentDoctor: '/governance-api/v1/TalentDoctor', //获取人才医生
	getTalentTraining: '/governance-api/v1/TalentTraining', //获取培训
	getDictionaries: '/common-api/v1/Dictionaries', //获取字典
	getSmartDeviceModel: '/governance-api/v1/SmartDeviceModel', //获取智能设备型号
	getAttachmentGroups: '/common-api/v1/AttachmentGroups.json', //获取附件组
	getReferralConsultation: '/governance-api/v1/ReferralConsultation.json', //获取转诊会诊
	getTalentPeople: '/governance-api/v1/TalentPeople.json', //获取重点人群数据
	getMedicalWorkerAnalysis: '/governance-api/v1/MedicalWorkerAnalysis.json', //获取医护人员分析
	getTalentActivities: '/governance-api/v1/TalentActivities.json', //获取健康教育活动数据
	getExaminationAnalysis: '/governance-api/v1/ExaminationAnalysis.json', //获取检查检验分析数据
	getSmartDeviceAnalysis: '/governance-api/v1/SmartDeviceAnalysis.json', //康养设备
	getTalentInfectiousDiseases: '/governance-api/v1/TalentInfectiousDiseases.json', //传染病
	getVaccinations: '/governance-api/v1/Vaccinations.json', //疫苗接种
	getPersonForms: '/governance-api/v1/PersonForms.json', //公共突发事件
	getPerformanceEvaluation: '/governance-api/v1/PerformanceEvaluations.json', //获取绩效评价
	getHouseholds: '/governance-api/v1/Households.json', //获取家庭信息接口
	getWorkLogs: '/governance-api/v1/WorkLogs.json', //获取巡诊/驻派日志或线路
};
